.Hero {
    position: absolute;
    z-index: 2;
    top: 0px;
    width: 100%;
    height: 100%;
    background-image: url("../../public/assets/img/paper.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.HeroText {
    flex: 3;
    color: white;
    mix-blend-mode: difference;
    font-size: 100px;
    font-family: 'Times New Roman', Times, serif;
}

.HeroSpacer {
    flex: 2;
}

.HeroUnderlay {
    width: 100%;
    z-index: -2;
    height: calc(100vh);
}

.NextArrow {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid white;
}

@keyframes bounce {
	0%, 100%, 20%, 50%, 80% {
		-webkit-transform: translateY(0);
		-ms-transform:     translateY(0);
		transform:         translateY(0)
	}
	40% {
		-webkit-transform: translateY(-30px);
		-ms-transform:     translateY(-30px);
		transform:         translateY(-30px)
	}
	60% {
		-webkit-transform: translateY(-15px);
		-ms-transform:     translateY(-15px);
		transform:         translateY(-15px)
	}
}

.bounce-button {  
    z-index: 2;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    color: white;
}

.bounce-button:hover {
    cursor: pointer;
    animation-name: bounce;
    -moz-animation-name: bounce;
}
