@import url('//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css');

.SocialButtons ul {
    width: 100%;
    justify-content: center;
  display:flex;
  margin: 0;
  padding: 0;
}
.SocialButtons ul li {
  list-style:none;
}
.SocialButtons ul li a {
  display:block;
  position:relative;
  width:100px;
  height:100px;
  line-height:100px;
  font-size:40px;
  text-align:center;
  text-decoration:none;
  color:#ffffff;
  margin: 0 30px;
  transition:.5s;
}
.SocialButtons ul li a span {
  position:absolute;
  transition: transform .5s;
}
.SocialButtons ul li a span:nth-child(1),
.SocialButtons ul li a span:nth-child(3){
  width:100%;
  height:3px;
  background:#ffffff;
}
.SocialButtons ul li a span:nth-child(1) {
  top:0;
  left:0;
  transform-origin: right;
}
.SocialButtons ul li a:hover span:nth-child(1) {
  transform: scaleX(0);
  transform-origin: left;
  transition:transform .5s;
}

.SocialButtons ul li a span:nth-child(3) {
  bottom:0;
  left:0;
  transform-origin: left;
}
.SocialButtons ul li a:hover span:nth-child(3) {
  transform: scaleX(0);
  transform-origin: right;
  transition:transform .5s;
}

.SocialButtons ul li a span:nth-child(2),
.SocialButtons ul li a span:nth-child(4){
  width:3px;
  height:100%;
  background:#404040;
}
.SocialButtons ul li a span:nth-child(2) {
  top:0;
  left:0;
  transform:scale(0);
  transform-origin: bottom;
}
.SocialButtons ul li a:hover span:nth-child(2) {
  transform: scale(1);
  transform-origin: top;
  transition:transform .5s;
}
.SocialButtons ul li a span:nth-child(4) {
  top:0;
  right:0;
  transform:scale(0);
  transform-origin: top;
}
.SocialButtons ul li a:hover span:nth-child(4) {
  transform: scale(1);
  transform-origin: bottom;
  transition:transform .5s;
}

.SocialButtons .linkedin:hover {
  color: #427eff;
}
.SocialButtons .linkedin:hover span { 
  background: #427eff;
}
.SocialButtons .github:hover {
  color: #af2fff;
}
.SocialButtons .github:hover span { 
  background: #af2fff;
}
.SocialButtons ul li a:hover:nth-child(3) {
  color: #c32aa3;
}
.SocialButtons ul li a:hover:nth-child(4) {
  color: #dd4b39;
}