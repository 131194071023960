.About {
    width: 100%;
    text-align: center;
}

.LangShowcase {
    height: 100px;
    width: 100px;
    margin: 20px;
}

.AboutMain {
    width: 100%;
    display: flex;
    align-items: center;
}

.AboutMain .Section {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 30px;
}

.AboutMain .Text {
    flex: 1;
    line-height: 20px;
    font-size: 17px;
    padding: 40px;
    color: rgb(20, 20, 20);
    font-style: italic;
}

.AboutMain .Photo {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-image: url("../../public/assets/img/Nikolas.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
