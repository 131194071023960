.hover-ul {
  display: inline-block;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  color: inherit
}

.hover-ul::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-ul:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

a {
  text-decoration: none;
  color: inherit;
}

.Title {
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  font-weight: bold;
  font-size: 50px;
  color: rgb(35, 35, 35);
  margin-bottom: 30px;
  margin-top: 30px;
}

.White-Text {
  color: white;
}

.ShowcaseWrap {
  width: 100%;
}

.Showcase {
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: all .3s ease;
}