/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
    .desktop {
        display: block;
    }
    .mobile {
        display: none !important;
    }
}

/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
    .desktop {
        display: block;
    }
    .mobile {
        display: none !important;
    }
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
    .mobile {
        display: block;
    }
    .desktop {
        display: none !important;
    }
}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .mobile {
        display: block;
    }
    .desktop {
        display: none !important;
    }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
    .mobile {
        display: block;
    }
    .desktop {
        display: none !important;
    }
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 10px) and (max-width: 480px) {
    .mobile {
        display: block;
    }
    .desktop {
        display: none !important;
    }
}

/* Mobile only */

@media (min-width: 10px) and (max-width: 1024px) {
    .Mobile-Padding {
        padding-left: 50px;
        padding-right: 50px;
    }
    .Hero-Text-Mobile {
        font-size: 80px !important;
    }
    .Header-Padding {
        padding-top: 0px; /* TODO: Make header lowered a bit on mobile */
    }
    .AboutMain .Section {
        flex-direction: column;
    }
    .ProjectWrapper {
        max-width: calc(100vw - 40px);
    }
    .FooterSplit {
        flex-direction: column !important;
    }
}