.Header {
    height: 100px;
    background-color: none;
    width: 100%;
    display: flex;
    flex-direction: row;
    color: white;
    position: fixed;
    top: 0;
    z-index: 100;
    transition: all .3s ease;
    font-family: Consolas;
}

.Header.hide {
    top: -100px;
}

.Header.opaque {
    background-color: white;
    color: rgb(18, 18, 18);
}

.Header .Name {
    font-size: 50px;
    font-family: 'Times New Roman';
}

.HeaderSection {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-around;
    text-align: center;
}


.MobileNav.hide {
    left: -300px;
}

.CloseMobileNav.hide {
    display: none;
}

.MobileNav {
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 300px;
    background-color: white;
    z-index: 100000;
    color: rgb(90, 90, 90);
    font-weight: bold;
    transition: all .3s ease;
}

.CloseMobileNav {
    top: 0px;
    left: 0px;
    position: sticky;
    height: 100vh;
    width: 100vw;
    background-color: black;
    opacity: 0.5;
    z-index: 1000;
}

.MobileNavButton {
    width: calc(100% - 20px);
    border-bottom: 1px solid gray;
    padding: 10px;
    text-align: left;
}

.MobileNavButtonWrap {
    border-top: 1px solid gray;
    padding: 10px;
}

.MobileNav a {
    text-decoration: none;
    color: inherit;
}

.Sandwich {
    left: 10px;
    display: flex;
    flex-direction: column;
    height: 25px;
    justify-content: space-between;
    width: 40px;
}

.Sandwich.Cross > .Bread {
    background-color: black;
}

.Bread {
    transition: all .3s ease;
    background-color: white;
    width: 100%;
    height: 5px;
    border-radius: 3px;
    mix-blend-mode: difference;
}
